




























































  import { Vue, Component, Watch } from 'vue-property-decorator';
  import { placeModule } from '@/store';
  import momnet from 'moment';
  import ThumbnailDetailModal from '@/pages/detail/ThumbnailDetailModal.vue';
  import { EventBus } from '@/config/config';
import { toLength } from 'lodash';

  @Component({
    components: {
      'thumbnail-detail-modal': () => import('@/pages/modal/ThumbnailDetailModal.vue'),
    }
  })
  export default class PlaceMenu extends Vue {
    public tab: any = '0';
    public isUpdate: boolean = false;
    public fetchMenu: any = [];
    public beforeRouteEnter(to, from, next) {
      placeModule.fetchPlaceInfoData(to.params.placeId);
      placeModule.fetchPlaceMenu(to.params.placeId);
      next();
    }
    public created() {
      if (this.$route.query.tab) {
        this.tab = `${this.$route.query.tab}`;
      }
      setTimeout(() => {
        const scrollXEl: any = document.querySelector('.menu-list-wrap .q-tabs__content');
        if (scrollXEl && this.placeMenu.length > 1) {
          if (Number(this.tab) > 1) {
            let left = 0;
            this.placeMenu.forEach((re: any, index: number) => {
              const tabEl: any = document.querySelector(`.tab-${index}`);
              if (Number(this.tab) > index) {
                left += tabEl.clientWidth;
              }
            });
            scrollXEl.scrollTo({
              left,
              behavior: 'smooth'
            })
          }
        }
      }, 100);
    }
    public thumbnailDetail(index, menuIndex) {
      EventBus.$emit('thumbnailIndex', index);
      this.fetchMenu = this.placeMenu[menuIndex];
      this.$modal.show('thumbnailDetail');
    }

    public updateToggle() {
      this.isUpdate = !this.isUpdate;
    }
    get place() {
      return placeModule.placeInfoData;
    }
    get placeMenu() {
      return placeModule.placeMenu;
    }
    get menuUnit() {
      return placeModule.placeMenuUnit;
    }
    get selectLang() {
      return this.$attrs['select-lang'];
    }
  }
